<template>
    <main class="m-grow">
        <img src="http://cssn.lexiangwx.com/png/R354620391395856384.png" alt="财商少年成长历程">
    </main>
</template>

<script>
    export default {
        name: "MGrow",
        metaInfo: {
            title: '财商少年 FQChildren',
            meta: [
                {
                    name: 'keyWords',
                    content: '中国财商少年, 财商教育, 财商少年, 少儿财商教育, 乐享无限'
                },
                {
                    name: 'description',
                    content: '财商少年品牌历程,普及家庭财商教育,全国少儿现金流游戏大赛'
                }
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .m-grow img {
        width: 100%;
    }
</style>
